@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
@font-face {
  font-family: Kiro;
  src: url(/static/media/Kiro-Bold.6e28fc4d.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}


body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: #141313;
  background-image: url(/static/media/footer-bg.f16c143e.png);
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}


@media (min-width:769px) and (max-width:1200px) {}



@media (max-width:768px) {}
